$(document).ready(function(){
   
    $(document).on("click", function(event){
        var $trigger = $(".dropdown");
        if($trigger !== event.target && !$trigger.has(event.target).length){
            $(".dropdown-menu-top").slideUp("fast");
        }
    });
    
});

//This is used to show the logout menu
function clickDropdownClass(){
    $(".dropdown").click(function(){
        $(this).find(".dropdown-menu-top").slideToggle("fast");
    });
}

function showJSToolTip(tagNameAttr) {
    let tag = $("input[name='" + tagNameAttr + "']");
    tag = tag.length === 0 ? $("textarea[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("select[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("mat-select[name='" + tagNameAttr + "']") : tag;

    if (tag.attr("alt") === "1") {
        return;
    }

    if (tag.val() == tag.attr("defaultValue")) {
        tag.val('').css({ 'color': '#000', 'font-style': 'normal' });
    }

    let nextTag = tag.next().is("span") === true ? tag.next() : tag.parent().next();
    nextTag.fadeIn(100);
}

function hideJSToolTip(tagNameAttr) {
    let tag = $("input[name='" + tagNameAttr + "']");
    tag = tag.length === 0 ? $("textarea[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("select[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("mat-select[name='" + tagNameAttr + "']") : tag;


    if (tag.val() == "") {
        tag.val($(this).attr("defaultValue")).css({ 'font-weight': 'normal', 'color': '#999' });
    }

    let nextTag = tag.next().is("span") === true ? tag.next() : tag.parent().next();
    nextTag.fadeOut(100);
}

function setJSDropdownWidth(tag){
    if(tag.tagName === 'I'){
        tag = tag.parentNode;
    }
    const tagWidth = tag.offsetWidth;
    $('.mat-menu-content').width(tagWidth);  
};